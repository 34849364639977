<div
  role="checkbox"
  [attr.aria-label]="ariaLabel"
  [attr.aria-checked]="isActive"
  class="switch"
  tabindex="0"
  (keydown.enter)="handleStateChange()"
  (click)="handleStateChange()"
  [class.is-active]="isActive"
  [class.switch--grey]="variant === 'Grey' || isDisabled"
  [style.--toggle-checkmark-icon]="toggleCheckmarkIconPath"
>
  <div class="switch_toggle"></div>
</div>
