import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Icon } from '../svg-icon/svg-icons';
import { AssetPathService } from '../assets-path.service';

@Component({
  selector: 'ros-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent {
  @Input() public variant?: '' | 'Grey' = '';
  @Input() public ariaLabel?= '';
  @Input() public isActive!: boolean;
  @Input() public isDisabled!: boolean;
  @Output() public toggle = new EventEmitter<boolean>();

  get toggleCheckmarkIconPath(): string {
    return this.assetPathService.getIconPath(Icon.CHECKMARK);
  }

  constructor(private readonly assetPathService: AssetPathService) { }

  public handleStateChange(): void {
    if (this.isDisabled) {
      return;
    }

    this.toggle.emit(this.isActive);
  }
}
