export enum Icon {
  NONE = 'None',
  ROSALYN = 'Rosalyn',
  DOTS = 'Dots',
  ALERT = 'Alert',
  COG = 'Cog',
  CHECKMARK = 'Checkmark',
  CHECKMARKXL = 'CheckmarkXL',
  CROSS = 'Cross',
  CHEVRON_UP = 'ChevronUp',
  CHEVRON_DOWN = 'ChevronDown',
  CHEVRON_DOWN_WHITE = 'ChevronDownWhite',
  CHEVRON_DOWN_BLACK = 'ChevronDownBlack',
  EXTERNAL = 'External',
  CHAT_BUBBLE = 'ChatBubble',
  CALENDAR_DOTS = 'CalendarDots',
  CALENDAR_CROSS = 'CalendarCross',
  CALENDAR_CHECKMARK = 'CalendarCheckmark',
  ROSALYN_LOGOTYPE = 'RosalynLogotype',
  DOWNLOAD = 'Download',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_RIGHTXL = 'ArrowRightXL',
  APP = 'App',
  CAMERA = 'Camera',
  MICROPHONE = 'Microphone',
  PREFLIGHT = 'Preflight',
  PROCTOR_EVENT = 'ProctorEvent',
  QUIZ_COMPLETE = 'QuizComplete',
  QUIZ_COMPLETED = 'QuizCompleted',
  QUIZ_HOME = 'QuizHome',
  QUIZ_HOME_CUT = 'QuizHomeCut',
  SCREEN = 'Screen',
  SOMETHING_WENT_WRONG = 'SomethingWentWrong',
  WELCOME = 'Welcome',
  WELCOME_CUT = 'WelcomeCut',
  USER_PROFILE = 'UserProfile'
}

export interface IconMapEntry {
  path: string;
  width?: number;
  height?: number;
  svgId?: string;
}

type IconMap = {
  [key in Icon]: IconMapEntry;
}

export const iconMap: IconMap = {
  [Icon.NONE]: {
    path: '',
    width: 0,
    height: 0,
    svgId: '',
  },
  [Icon.APP]: {
    path: 'assets/icons/app.svg',
    svgId: 'app',
    width: 13,
    height: 13,
  },
  [Icon.ALERT]: {
    path: 'assets/icons/alert.svg',
    svgId: 'alert',
    width: 2,
    height: 11,
  },
  [Icon.CAMERA]: {
    path: 'assets/icons/camera.svg',
    svgId: 'camera',
    width: 24,
    height: 20,
  },
  [Icon.MICROPHONE]: {
    path: 'assets/icons/microphone.svg',
    svgId: 'microphone',
    width: 16,
    height: 26,
  },
  [Icon.PREFLIGHT]: {
    path: 'assets/icons/preflight.svg',
    svgId: 'preflight',
    width: 275,
    height: 269,
  },
  [Icon.PROCTOR_EVENT]: {
    path: 'assets/icons/proctor-event.svg',
    svgId: 'proctorEvent',
    width: 275,
    height: 218,
  },
  [Icon.QUIZ_COMPLETE]: {
    path: 'assets/icons/quiz-complete.svg',
    svgId: 'quizComplete',
    width: 275,
    height: 275,
  },
  [Icon.QUIZ_HOME]: {
    path: 'assets/icons/quiz-home.svg',
    svgId: 'quizHome',
    width: 275,
    height: 187,
  },
  [Icon.QUIZ_HOME_CUT]: {
    path: 'assets/icons/quiz-home-cut.svg',
    svgId: 'quizHomeCut',
    width: 356,
    height: 265,
  },
  [Icon.SCREEN]: {
    path: 'assets/icons/screen.svg',
    svgId: 'screen',
    width: 22,
    height: 22,
  },
  [Icon.SOMETHING_WENT_WRONG]: {
    path: 'assets/icons/something-went-wrong.svg',
    svgId: 'somethingWentWrong',
    width: 276,
    height: 221,
  },
  [Icon.USER_PROFILE]: {
    path: 'assets/icons/user-profile.svg',
    svgId: 'userProfile',
    width: 55,
    height: 55,
  },
  [Icon.WELCOME]: {
    path: 'assets/icons/welcome.svg',
    svgId: 'welcome',
    width: 275,
    height: 182,
  },
  [Icon.WELCOME_CUT]: {
    path: 'assets/icons/welcome-cut.svg',
    svgId: 'welcomeCut',
    width: 349,
    height: 293,
  },
  [Icon.ARROW_RIGHTXL]: {
    path: 'assets/icons/arrow-right-xl.svg',
    width: 17,
    height: 13,
    svgId: 'arrowRightXL',
  },
  [Icon.ARROW_RIGHT]: {
    path: 'assets/icons/arrow-right.svg',
    width: 7,
    height: 6,
    svgId: 'arrowRight',
  },
  [Icon.CHAT_BUBBLE]: {
    path: 'assets/icons/chat-bubble.svg',
    width: 16,
    height: 16,
    svgId: 'chatBubble',
  },
  [Icon.CALENDAR_DOTS]: {
    path: 'assets/icons/calendar-dots.svg',
    width: 19,
    height: 20,
    svgId: 'calendarDots',
  },
  [Icon.CALENDAR_CHECKMARK]: {
    path: 'assets/icons/calendar-checkmark.svg',
    width: 19,
    height: 20,
    svgId: 'calendarCheckmark',
  },
  [Icon.CALENDAR_CROSS]: {
    path: 'assets/icons/calendar-cross.svg',
    width: 19,
    height: 20,
    svgId: 'calendarCross',
  },
  [Icon.CHECKMARK]: {
    path: 'assets/icons/checkmark.svg',
    width: 11,
    height: 9,
    svgId: 'checkmark',
  },
  [Icon.CHECKMARKXL]: {
    path: 'assets/icons/checkmarkXL.svg',
    width: 33,
    height: 27,
    svgId: 'checkmarkXL',
  },
  [Icon.CHEVRON_UP]: {
    path: 'assets/icons/chevron-up.svg',
    width: 8,
    height: 6,
    svgId: 'chevronUp',
  },
  [Icon.CHEVRON_DOWN]: {
    path: 'assets/icons/chevron-down.svg',
    width: 8,
    height: 6,
    svgId: 'chevronDown',
  },
  [Icon.CHEVRON_DOWN_WHITE]: {
    path: 'assets/icons/chevron-down-white.svg',
    width: 6,
    height: 5,
    svgId: 'chevronDownWhite',
  },
  [Icon.CHEVRON_DOWN_BLACK]: {
    path: 'assets/icons/chevron-down-black.svg',
    width: 6,
    height: 5,
    svgId: 'chevronDownBlack',
  },
  [Icon.COG]: {
    path: 'assets/icons/cog.svg',
    width: 13,
    height: 13,
    svgId: 'cog',
  },
  [Icon.CROSS]: {
    path: 'assets/icons/cross.svg',
    width: 9,
    height: 9,
    svgId: 'cross',
  },
  [Icon.DOTS]: {
    path: 'assets/icons/dots.svg',
    width: 11,
    height: 3,
    svgId: 'dots',
  },
  [Icon.DOWNLOAD]: {
    path: 'assets/icons/download.svg',
    width: 16,
    height: 16,
    svgId: 'download',
  },
  [Icon.EXTERNAL]: {
    path: 'assets/icons/external.svg',
    width: 10,
    height: 10,
    svgId: 'external',
  },
  [Icon.ROSALYN]: {
    path: 'assets/icons/rosalyn-logo-xs.svg',
    width: 17,
    height: 17,
    svgId: 'rosalyn',
  },
  [Icon.ROSALYN_LOGOTYPE]: {
    path: 'assets/icons/rosalyn-logotype.svg',
    width: 71,
    height: 40,
    svgId: 'rosalynLogotype',
  },
  [Icon.QUIZ_COMPLETED]: {
    path: 'assets/icons/quiz-completed.svg',
    width: 275,
    height: 275,
    svgId: 'quizCompleted',
  },
};
