import {
  DetailedEvent,
  Severity,
} from './event.model';
import { Filters } from './response.model';

export enum SessionStatus {
  WAITING_FOR_PROCTORING = 'waiting for proctoring',
  WAITING_FOR_FINAL_APPROVAL = 'waiting for final approval',
  PASSED = 'passed',
  FLAGGED = 'flagged',
  PROCTORING_IN_PROGRESS = 'proctoring in progress'
}

export enum SessionState {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_STREAM_MISSING = 'incomplete - stream missing',
}

export interface SessionTimeline {
  offset: string,
  message: string,
  createdAt: Date,
}

export interface ProctoringRules {
  usePrivateTestArea?: boolean;
  visibleFace?: boolean;
  doNotAllowInteractionWithOthers?: boolean;
  doNotAllowOtherMaterials?: boolean;
  doNotAllowNoiseMakingDevices?: boolean;
  doNotAllowAdditionalDevices?: boolean;
  allowMouseKeyboardMonitorOnly?: boolean;
  doNotAllowAppsOrBroswerTabs?: boolean;
  doNotAllowExamContentSharing?: boolean;
  doNotAllowTextPrediction?: boolean;
}

export interface Session {
  id: number;
  sessionId: string;
  examName: string;
  examinee: {
    id: number,
    firstName: string,
    lastName: string,
  };
  severity: Severity;
  status: SessionStatus;
  createdAt: string;
  finishedAt: string;
  startedAt: string;
  duration: number;
  videoSource: string;
  events: DetailedEvent[];
  learnerPhotoUrl?: string;
  learnerIDPhotoUrl?: string;
  reprocessDate?: Date;
  sessionTimeline: SessionTimeline[];
  state: SessionState;
  proctoringRules?: ProctoringRules;
  videoCheckCompleted: boolean;
}

export interface SessionListFilters extends Filters {
  severity: Severity;
  eventsMax: number;
  eventsMin: number;
  status: SessionStatus,
  quizId: string,
  state: SessionState,
}

export interface EventsRange {
  min: number,
  max: number
}
export interface Comment {
  id: number,
  comment: string
}
