import { 
  Inject,
  Injectable,
  Optional
} from '@angular/core';

export interface RosalynComponentsConfig {
  localeCode?: string;
}

@Injectable({ providedIn: 'root' })
export class RosalynComponentsConfigService {
  private config: RosalynComponentsConfig = {};

  constructor(@Optional() @Inject('ROSALYN_CONFIG') config: RosalynComponentsConfig = {}) {
    if(config===null) {
      this.config = {};
    } else {
      this.config = config;
    }
  }

  public getConfig(): RosalynComponentsConfig  {
    return this.config;
  }

  public getLocaleCode(): string | undefined {
    return this.config.localeCode;
  }
}
