<div
  role="checkbox"
  [attr.aria-label]="ariaLabel"
  [attr.aria-checked]="isActive"
  class="checkbox"
  [attr.tabindex]="isDisabled ? -1 : 0"
  (keydown.enter)="handleStateChange()"
  (click)="handleStateChange()"
  [class.is-active]="isActive"
  [class.is-disabled]="isDisabled"
  [style.--toggle-checkmark-icon]="toggleCheckmarkIconPath"
>
  <div class="checkbox_toggle"></div>
</div>
