import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AssetPathService } from '../assets-path.service';
import { Icon } from '../svg-icon/svg-icons';

@Component({
  selector: 'ros-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input() public ariaLabel?= '';
  @Input() public isActive!: boolean;
  @Input() public isDisabled!: boolean;
  @Output() public toggle = new EventEmitter<boolean>();

  constructor(private readonly assetPathService: AssetPathService) {}

  get toggleCheckmarkIconPath(): string {
    return this.assetPathService.getIconPath(Icon.CHECKMARK);
  }

  public handleStateChange(): void {
    if (this.isDisabled) {
      return;
    }

    this.isActive = !this.isActive;

    this.toggle.emit(this.isActive);
  }
}
