import { Injectable } from "@angular/core";
import { RosalynComponentsConfigService } from "./rosalyn-components-config.service";
import { Icon, iconMap } from "./svg-icon/svg-icons";

@Injectable({ providedIn: 'root' })
export class AssetPathService {
  constructor(private configService: RosalynComponentsConfigService) { }

  getIconPath(icon: Icon): string {
    const localeCode = this.configService.getLocaleCode();
    const basePath = iconMap[icon].path;
    if (localeCode) {
      return `url('/${localeCode}/${basePath}')`;
    }

    return `url('/${basePath}')`;
  }
} 