import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { AssetPathService } from '../assets-path.service';
import { Icon } from '../svg-icon/svg-icons';

export interface DropdownItem {
  title: string;
  isBold: boolean;
  url?: string;
  value?: any;
  isActive?: boolean;
}

export interface DropdownPosition {
  top?: string | number;
  left?: string | number;
  right?: string | number;
  bottom?: string | number;
}

@Component({
  selector: 'ros-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() public textAlign: 'left' | 'center' = 'center';
  @Input() public isActive: boolean = false;
  @Input() public htmlTitle: string | undefined = '';
  @Input() public title: string | undefined = '';
  @Input() public items!: DropdownItem[];
  @Input() public ariaLabelledBy?: string = '';
  @Input() public dropdownId?: string = 'dropdownList';
  @Input() public variant: 'Dark' | 'Outline' | 'Text-only' | 'Filter' = 'Text-only';
  @Input() public dropdownPosition?: DropdownPosition;
  public _isVisible!: boolean;
  public get isVisible(): boolean {
    return this._isVisible;
  }

  @Input() public set isVisible(value: boolean) {
    this._isVisible = value;
    if (value) {
      this.dropdown?.nativeElement.querySelector('a')?.focus();
    }
  }
  @Output() public onEscapeKeyup = new EventEmitter<void>();
  @Output() public toggle = new EventEmitter<void>();
  @Output() public itemClick = new EventEmitter<DropdownItem>();

  @ViewChild('dropdown') public dropdown!: ElementRef<HTMLElement>;
  public currentlyFocusedItemIndex = 0;

  constructor(private readonly assetPathService: AssetPathService) {}

  public toggleContextMenuVisibility(): void {
    this.toggle.emit()
  }

  public onEnterItem(item: DropdownItem): void {
    this.onItemClick(item);
    this.toggleContextMenuVisibility();
  }

  public onItemClick(item: DropdownItem): void {
    this.itemClick.emit(item)
  }

  public onArrowDown(prefix: 'item' | 'link'): void {

    this.currentlyFocusedItemIndex += 1;
    const nextElement = <HTMLElement>
      this.dropdown?.nativeElement.querySelector(`${prefix === 'link' ? 'a' : 'div'}[id=${prefix}-${this.currentlyFocusedItemIndex}]`);

    if (nextElement) {
      nextElement.focus();
    }
  }

  public onArrowUp(prefix: 'item' | 'link'): void {
    this.currentlyFocusedItemIndex -= 1;
    const prevElement = <HTMLElement>
      this.dropdown?.nativeElement.querySelector(`${prefix === 'link' ? 'a' : 'div'}[id=${prefix}-${this.currentlyFocusedItemIndex}]`);

    if (prevElement) {
      prevElement.focus();
    }
  }

  getIconStyles(): { [key: string]: string } {
    return {
      '--chevron-icon': this.assetPathService.getIconPath(Icon.CHEVRON_DOWN),
      '--chevron-white-icon': this.assetPathService.getIconPath(Icon.CHEVRON_DOWN_WHITE),
      '--chevron-black-icon': this.assetPathService.getIconPath(Icon.CHEVRON_DOWN_BLACK),
      '--toggle-checkmark-icon': this.assetPathService.getIconPath(Icon.CHECKMARK)
    };
  }
}
